/*
Copyright 2024 Wand.ai
 */

.wa_SavedMessagesPanel_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.wa_SavedMessagesPanel_header {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid $roomlist-separator-color;

    .wa_SavedMessagesPanel_header_title {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        height: $headerHeight;
        padding: 0 12px;
        box-sizing: border-box;

        h3 {
            margin: 0;
        }
    }

    .wa_SavedMessagesPanel_header_back_button {
        cursor: pointer;
    }
}

.wa_SavedMessagesPanel_tabs {
    display: flex;
    padding: 0 12px;
    gap: 4px;
    border-bottom: 1px solid $roomlist-separator-color;
    padding-bottom: 21px;
}

.wa_SavedMessagesPanel_tab {
    padding: 8px;
    cursor: pointer;
    position: relative;
    color: $secondary-content;
    border-radius: 8px;
    font-weight: var(--cpd-font-weight-semibold);

    &:hover {
        color: $primary-content;
    }

    &--active {
        color: $primary-content;
        background-color: $roomlist-highlights-color;
    }
}

.wa_SavedMessagesPanel_content {
    display: flex;
    flex-direction: column;
    height: calc(100% - $headerHeight - 37px); /* header height + tabs height */
    width: 100%;
    overflow: scroll;
}

.wa_SavedMessagesPanel_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: $secondary-content;
}
