@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.wa_MessageQuickActionsWrapper {
    padding: 4px 0px 4px 20px;
    background-color: $timeline-background-color;
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    animation: slideUp 0.7s ease;
    overflow: scroll;
}
.wa_MessageQuickActionsContextMenu {
    background-color: $roomlist-background-color;
    display: flex;
    flex-direction: column;
    border: none;

    .wa_MessageQuickActionButton {
        width: 100%;
        border-radius: 0px;
    }

    .wa_MessageQuickActionButton:first-child {
        border-radius: 8px 8px 0 0;
    }

    .wa_MessageQuickActionButton:last-child {
        border-radius: 0 0 8px 8px;
    }

    .wa_MessageQuickActionButton:only-child {
        border-radius: 8px 8px;
    }
}
