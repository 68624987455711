/*
Copyright 2025 Wand.ai
 */

.wa_CreateAssistantDialog {
    /* Dialog width */
    &.mx_Dialog_fixedWidth {
        width: 450px;
    }

    /* Content wrapper */
    .wa_CreateAssistantDialog_content {
        margin: 32px 0 40px;
        font: var(--cpd-font-body-md-regular);
        color: $primary-content;
        word-wrap: break-word;
    }

    /* Text styles */
    p {
        margin: 0;
        color: $roomlist-text-secondary-color;
        font-size: $font-12px;
    }
    .wa_CreateAssistantDialog_custom_assistant_description {
        margin-bottom: $spacing-16;
        font-size: $font-15px;
    }

    /* Fields & Containers */
    .wa_CreateAssistantDialog_field_container {
        margin-bottom: $spacing-20;
    }

    .wa_CreateAssistantDialog_field_error {
        color: $alert;
    }

    /* Details Section */
    .wa_CreateAssistantDialog_details_summary {
        list-style: none;
        font-weight: var(--cpd-font-weight-semibold);
        cursor: pointer;
        color: var(--cpd-color-text-primary);
        text-decoration: underline;
        width: fit-content;
        text-transform: capitalize;

        &:not(:focus-visible) {
            outline: none;
        }
    }

    .wa_CreateAssistantDialog_access_container {
        display: none;
    }

    /* Join Rule Dropdown */
    .wa_CreateAssistantDialog_join_rule_container {
        margin-bottom: $spacing-20;

        .mx_Dropdown_input {
            border: 1px solid $roomlist-separator-color;
        }

        .mx_Dropdown_option {
            text-transform: capitalize;
        }
    }

    /* Toggle Container */
    .wa_CreateAssistantDialog_toggle {
        margin-top: $spacing-24;
        margin-bottom: $spacing-32;
        text-transform: capitalize;
        font-weight: 500;
    }

    .mx_Dialog_primary {
        text-transform: capitalize;
    }
}

.wa_CreateAssistant_icon::before {
    mask-image: url("$(res)/img/wand/assistant.svg");
}
