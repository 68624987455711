.wa_MessageQuickActionButton {
    display: inline-flex;
    align-items: center;
    padding: $spacing-8;
    border-radius: 8px;
    cursor: pointer;
    background-color: $roomlist-background-color;
    width: fit-content;

    &:hover {
        background-color: $roomlist-highlights-color;
    }

    .wa_MessageQuickActionButton_content {
        display: flex;
        align-items: center;
        gap: $spacing-8;
    }

    .wa_MessageQuickActionButton_content_ellipsis {
        height: 18px;
        align-items: flex-end;
    }

    .wa_MessageQuickActionButton_title {
        font-size: $font-12px;
        font-weight: var(--cpd-font-weight-medium);
    }

    .wa_MessageQuickActionButton_icon {
        color: $icon-button-color;
    }
}
