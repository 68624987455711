.wa_CatchupEventTile_wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 12px;
    cursor: pointer;
    border-bottom: 1px solid $roomlist-separator-color;

    &:hover {
        background: $roomlist-highlights-color;
    }

    .wa_CatchupEventTile_body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0px 8px 0px 12px;
        overflow: hidden;
        gap: 4px;

        .wa_CatchupEventTile_body_footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }

        .wa_CatchupEventTile_body_origin_wrapper {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-inline-end: 11px;
        }

        .wa_CatchupEventTile_body_reaction {
            display: flex;
        }

        .wa_CatchupEventTile_body_type {
            color: #969798;
            font-size: $font-12px;
            font-weight: var(--cpd-font-weight-semibold);
            line-height: $font-17px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .wa_CatchupEventTile_body_type_link {
            color: #9663ea;
            cursor: pointer;
        }

        .wa_CatchupEventTile_body_timestamp {
            color: #969798;
            font-size: $font-12px;
            line-height: $font-17px;
            text-wrap: nowrap;
        }
    }

    .wa_CatchupEventTile_unread_indicator_wrapper {
        width: 12px;
    }

    .wa_CatchupEventTile_unread_indicator {
        background-color: #9663ea !important;
        border: 1px solid #e1dfeb;
        width: 12px !important;
        height: 12px !important;
    }

    .mx_EventTile_body {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .mx_DisambiguatedProfile {
        line-height: $font-17px;

        .mx_DisambiguatedProfile_displayName {
            font-size: $font-14px;
            font-weight: var(--cpd-font-weight-semibold);
            color: #5c7fe3;
        }
    }
}
