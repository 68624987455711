/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthBody_language {
    width: 100%;
}

.mx_AuthBody_language .mx_Dropdown_input {
    border: none;
    font: var(--cpd-font-body-md-semibold);
    color: $authpage-lang-color;
    width: auto;
}

.mx_AuthBody_language .mx_Dropdown_arrow {
    background: $authpage-lang-color;
}

.wa_AuthBody_language {
    .mx_Dropdown_input {
        border: none;
        font: var(--cpd-font-body-md-semibold);
        color: $authpage-lang-color;

        .mx_Dropdown_option {
            color: #1d0941;
            opacity: 50%;
            font: var(--cpd-font-body-md-regular);
            font-size: $font-16px;
        }
    }

    .mx_Dropdown_arrow {
        background: $authpage-lang-color;
        opacity: 50%;
    }
}
