.wa_AgentManagedSourcesSearch {
    display: flex;
    align-items: center;
    background-color: $info-plinth-bg-color;
    border-radius: 28px;
    padding: 10px 12px;
    position: relative;
}

.wa_AgentManagedSourcesSearch_icon {
    position: absolute;
    top: calc(50% - 6px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wa_AgentManagedSourcesSearch_input {
    width: 100%;
    background: transparent;
    border: none !important;
    outline: none;
    margin: 0px !important;
    padding: 0px 0px 0px 20px !important;

    &:focus {
        outline: none;
    }
}
