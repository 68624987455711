.wa_SavedEventTile_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid $roomlist-separator-color;
    cursor: pointer;
    padding: 20px;
    position: relative;

    &:hover {
        background: $roomlist-highlights-color;
    }

    .mx_MessageActionBar {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.wa_SavedEventTile_wrapper:hover .mx_MessageActionBar,
.wa_SavedEventTile_wrapper.wa_SavedEventTile_wrapper_actionBarFocused .mx_MessageActionBar,
[data-whatinput="keyboard"] .wa_SavedEventTile_wrapper:focus-within .mx_MessageActionBar,
.wa_SavedEventTile_wrapper:focus-visible:focus-within .mx_MessageActionBar {
    visibility: visible;
}

.wa_SavedEventTile_header {
    font-size: $font-13px;
    font-weight: 500;
    color: #705787;
}

.wa_SavedEventTile_body_wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .wa_SavedEventTile_body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0px 8px 0px 12px;
        overflow: hidden;
        gap: 4px;

        .wa_SavedEventTile_body_header {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .wa_SavedEventTile_body_origin_wrapper {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-inline-end: 11px;
        }

        .wa_SavedEventTile_body_reaction {
            display: flex;
        }

        .wa_SavedEventTile_body_type {
            color: #969798;
            font-size: $font-12px;
            font-weight: var(--cpd-font-weight-semibold);
            line-height: $font-17px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .wa_SavedEventTile_body_type_link {
            color: #9663ea;
            cursor: pointer;
        }

        .wa_SavedEventTile_body_dateTime {
            color: #969798;
            font-size: $font-14px;
            text-wrap: nowrap;
            margin-inline-start: 16px;
            margin-inline-end: auto;
        }
    }

    .wa_SavedEventTile_unread_indicator_wrapper {
        width: 12px;
    }

    .mx_EventTile_body {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .mx_DisambiguatedProfile {
        .mx_DisambiguatedProfile_displayName {
            font-size: $font-14px;
            font-weight: var(--cpd-font-weight-semibold);
            color: #5c7fe3;
        }
    }
}
