.wa_CatchupModalButton {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    color: $secondary-content;
    margin: 18px auto 8px auto;

    width: 40px;
    height: 40px;

    &.panelIsOpen {
        border: 3px solid var(--activeBorder-color);
        border-radius: 12px;
    }

    &.expanded {
        margin-left: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 20px);
        gap: 8px;

        &.panelIsOpen {
            border: unset;
            border-radius: 12px;
            background-color: $quaternary-content;
        }

        svg {
            padding: 8px;
        }

        &:hover {
            color: $secondary-content;
            background: none;
        }

        .wa_CatchupModalButton_notification_count {
            position: absolute;
            left: 80%;
        }
    }

    &:hover {
        background-color: $quaternary-content;
        color: $primary-content;
    }
}

.wa_CatchupModalButton_catchup_modal_wrapper {
    height: 520px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px 0px #00000040;
    border-radius: 8px;
}
.wa_CatchupModalButton_notification_count {
    position: absolute;
    top: 5%;
    left: 70%;
}
