/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthFooter {
    text-align: center;
    width: 100%;
    font: var(--cpd-font-body-md-regular);
    padding: 20px 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.mx_AuthFooter a:link,
.mx_AuthFooter a:hover,
.mx_AuthFooter a:visited {
    color: $accent-fg-color;
    margin: 0 22px;
}

.wa_AuthFooter {
    text-align: center;
    width: 100%;
    font: var(--cpd-font-body-md-regular);
    font-size: $font-16px;
    padding: 64px 0;
    display: flex;
    justify-content: center;
    gap: 32px;
    align-items: baseline;
}

.wa_AuthFooter a:link,
.wa_AuthFooter a:hover,
.wa_AuthFooter a:visited {
    color: #1d0941;
    opacity: 50%;
}
