.wa_AgentManagedSourcesTable {
    height: 100%;
    flex: 1;
    overflow: auto;
    width: 100%;
    min-width: 580px;
}

.wa_AgentManagedSourcesTable_HeaderRow {
    border: 0;
    background-color: $timeline-highlights-color;
    height: 52px;
}

.wa_AgentManagedSourcesTable_HeaderContent {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.1em;
    padding-inline: 8px;
}

.wa_AgentManagedSourcesTable_Body {
    font-size: 14px;
    font-weight: 500;
}

.wa_AgentManagedSourcesTable_Row {
    border-bottom: 1px solid $roomlist-separator-color;

    &:hover {
        background-color: $timeline-highlights-color;
    }
}

.wa_AgentManagedSourcesTable_Cell {
    padding-inline: 8px !important;
    font-weight: normal;

    &--actions {
        padding: 0;
    }

    &--default {
        padding: 16px 24px;
    }

    &--type {
        text-transform: capitalize;
    }

    &_withIcon {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
    &_deleteIcon {
        color: $icon-default-color;
        width: 20px;
        height: 20px;
    }
}

.wa_TableComponents {
    &_container {
        display: flex;
        gap: 4px;
        flex-direction: column;
        color: $roomlist-text-color;
    }

    &_container_failed {
        display: flex;
        gap: 8px;
        flex-direction: row;
        color: $roomlist-text-color;

        .mx_InfoTooltip {
            position: relative;
            display: flex;
            align-items: center;
        }

        .mx_InfoTooltip_icon_warning::before {
            background-color: $alert;
            position: absolute;
        }
    }

    &_container_progress {
        .mx_ProgressBar {
            width: 100%;
            height: 4px;
            color: #4dae5d;
            border-radius: 30px;
            border: none;

            &::-moz-progress-bar {
                background: #4dae5d;
            }
            &::-webkit-progress-value {
                background: #4dae5d;
            }
        }
    }

    &_status {
        &--completed,
        &--inQueue,
        &--processing {
            font-weight: normal;
        }

        &--failed {
            font-weight: 600;
            color: $alert;
        }
    }

    &_icon {
        &_container {
            display: flex;
            height: 24px;
            width: 24px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &--files {
                background-color: $roomlist-background-color;
            }

            &--apps {
                background-color: $roomlist-background-color;
            }
        }

        &--files {
            width: 12px;
            height: 12px;
            color: $icon-default-color;
        }

        &--apps {
            width: 16px;
            height: 16px;
        }

        &--outlook {
            border-radius: 8px;
        }
    }

    &_header {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0 8px;
        font-weight: 600;
        color: $roomlist-text-color;

        &_arrow {
            margin-left: 8px;
            height: 10px;
            width: 10px;
            color: $quaternary-content;

            &:hover {
                color: rgba($brand-nav-tabs, 0.5);
            }

            &--asc {
                transform: rotate(180deg);
                color: $brand-nav-tabs;
            }

            &--desc {
                color: $brand-nav-tabs;
            }
        }
    }
}
