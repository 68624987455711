.wa_AnalysisDone {
    padding-top: $spacing-3;
    display: flex;
    flex-direction: column;

    &__isOpened {
        .wa_AnalysisDone__Accordion {
            display: block;
        }
    }

    .mx_EventTile_collapsedCodeBlock {
        margin-bottom: $spacing-4;
    }
}

.wa_AnalysisDone__Accordion {
    padding-top: $spacing-8;
    display: none;
}

.wa_AnalysisDone__DropdownToggle {
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: 700;
    font-size: $font-14px;
    line-height: $font-18px;
    color: $icon-button-color;
}
