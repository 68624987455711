.wa_SavedMessages_empty_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-block-start: 80px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.wa_SavedMessages_empty_header {
    margin: unset;
}

.wa_SavedMessages_empty_help_message {
    max-width: 513px;
    text-align: center;
    padding-inline: 60px;
}

.wa_SavedMessages_empty_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #f1effd;
}
