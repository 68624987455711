/*
Copyright 2024 Wand.ai
 */

$footerHeight: 92px;

.wa_AIPanel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .mx_EventTile[data-layout="bubble"] {
        margin-left: 16px;
        margin-right: 16px;
    }

    .mx_EventTile_avatar {
        display: none;
    }

    .mx_ReadReceiptGroup {
        display: none;
    }

    .mx_EventTile_line {
        max-width: 100% !important;
        flex-direction: column;
    }

    .mx_EventTile[data-layout="bubble"][data-self="false"]:not(.mx_EventTile_noBubble)
        .mx_EventTile_line:not(.mx_EventTile_mediaLine) {
        width: calc(100% - var(--MessageActionBar-size-box)) !important;
        background-color: $timeline-background-color;
    }

    .mx_EventTile[data-layout="bubble"]::before {
        background-color: $timeline-background-color !important;
    }

    .mx_EventTile[data-layout="bubble"][data-self="false"] .mx_MessageActionBar {
        inset-inline-start: auto;
        right: 0;
    }
}

.wa_AIPanel_header {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    height: $headerHeight;
    box-sizing: border-box;
    padding: 0 var(--cpd-space-4x);
    border-bottom: 1px solid $separator;
    background-color: $sidebar-color;
    transition: background-color 0.3s ease;
    justify-content: space-between;
}

.wa_AIPanel_header.collapsed {
    background-color: $accent-color;
}

.wa_AIPanel_header .wand_logo {
    height: 32px;
    width: 32px;
}

.wa_assistant_name_container {
    display: flex;
    flex-direction: column;
    margin-inline-start: 12px;
    min-width: 0;
    max-width: 100%;
}

.wa_assistant_name_container_default {
    flex-direction: row;
    align-items: center;
}

.wa_assistant_name_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    min-width: 0;
}


.wa_assistant_hide_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: auto;
    cursor: pointer;
}

.wa_assistant_hide_icon {
    margin-inline-end: 8px;
    color: $icon-button-color;
    transition: transform 0.6s ease;
}

#wa_AIPanel_header.collapsed .wa_assistant_hide_icon {
    transform: rotate(180deg);
    color: #ffffff;
}

.wa_assistant_hide_text {
    color: $primary-color;
    font-size: 14px;
    font-weight: 600;
    transition: transform 0.3s ease;
}

#wa_AIPanel_header.collapsed .wa_assistant_hide_text {
    color: #ffffff;
}

.wa_assistant_name_container div:first-child {
    color: $roomlist-text-color;
    font-size: 14px;
    font-weight: 600;
}

#wa_AIPanel_header.collapsed .wa_assistant_name_container div:first-child {
    color: #ffffff;
}

.wa_AIPanel_header .wa_assistant_name_container div:first-child + div {
    color: rgba($roomlist-text-color, 0.5);
    font-size: 10px;
    font-weight: 600;
}

#wa_AIPanel_header.collapsed .wa_assistant_name_container div:first-child + div {
    color: #ffffff;
}

.wa_AIPanel_timeline {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: calc(100% - ($headerHeight + $footerHeight));
}

.wa_AIPanel_footer {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    bottom: 0;
    padding-bottom: 16px;

    .mx_SendMessageComposer .mx_BasicMessageComposer {
        min-height: 55px;
    }
}

.wa_AIPanel .mx_MessageComposer_wrapper {
    background-color: $roomlist-background-color;
    border: 1px solid $sidebar-color;
    border-radius: 28px;
    margin: 0 16px;
    padding: 2px 20px;
}

.wa_assistant_settings {
    margin-right: 10px;
    .wa_AiPanel_iconSettings::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/settings-solid.svg");
    }
}
.wa_assistant_actions {
    display: flex;
    align-items: center;
}

.wa_assistant_logo_container {
    display: flex;
    min-width: 0;
    max-width: 100%;
}

#wa_AIPanel_header.collapsed .wa_assistant_settings_icon {
    color: #ffffff;
}
