/*
Copyright 2024 Wand.ai
*/

.wa_AgentManagedSourcesHeader {
    display: flex;
    align-items: center;
    padding: 20px 24px;
}

.wa_AgentManagedSourcesHeader_roomInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.wa_AgentManagedSourcesHeader_backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: var(--cpd-space-2x);
    cursor: pointer;
    color: $primary-color;

    .wa_BacKIcon {
        width: 20px;
        height: 20px;
        color: $primary-color;
    }
}

.wa_AgentManagedSourcesHeader_roomName {
    color: $roomlist-text-color;
    font-size: $font-16;
    font-weight: 600;
    line-height: 20px;
}

.wa_AgentManagedSourcesHeader_desc {
    color: $roomlist-text-color;
    font-size: 12px;
}
