.wa_CatchupPanel_empty_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block-start: 130px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.wa_CatchupPanel_empty_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #f1effd;
}
