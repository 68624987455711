/*
Copyright 2024 Wand.ai
 */

.wa_CatchupPanel_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.wa_CatchupPanel_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: $headerHeight;
    padding: 0 12px;
    box-sizing: border-box;
    border-bottom: 1px solid $roomlist-separator-color;

    h3 {
        margin: 0;
    }

    .wa_CatchupPanel_header_title {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }

    .wa_CatchupPanel_header_back_button {
        width: 20px;
        height: 20px;

        cursor: pointer;
    }

    .wa_CatchupPanel_header_options_toggle_wrapper {
        display: flex;
        gap: 6px;
        flex-direction: row;
        align-items: center;
    }

    .wa_CatchupPanel_header_options_toggle_title {
        font-size: $font-12px;
        font-weight: var(--cpd-font-weight-semibold);
    }
}

.wa_CatchupPanel_content {
    display: flex;
    flex-direction: column;
    height: calc(100% - $headerHeight);
    width: 100%;
    overflow: scroll;
}
