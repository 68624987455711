/*
Copyright 2024 Wand.ai
*/

.wa_AgentManagedSourcesBody {
    display: flex;
    flex-direction: column;
    padding: 0 52px;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 32px;

    .wa_AgentManagedSourcesBody_addButton {
        width: 160px;

        .mx_AccessibleButton {
            width: calc(160px - 36px);
            gap: 8px;
        }
    }

    .white-icon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
    }

    .mx_LargeLoader {
        align-self: center;
    }
}

.wa_AgentManagedSourcesBody_addButton_list {
    width: 160px;
}

.wa_AgentManagedSourcesBody_addButton_option {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: calc(160px - 20px);

    .mx_IconizedContextMenu_label {
        font-size: $font-14px;
        font-weight: 400;
        line-height: 18px;
    }
}

.wa_AgentManagedSourcesBody_addButton_icon_upload::before {
    mask-image: url("$(res)/img/wand/icons-enterprise/paper-clip.svg");
    background-color: $icon-button-color !important;
}

.wa_AgentManagedSourcesBody_addButton_icon_connect::before {
    mask-image: url("$(res)/img/wand/icons-enterprise/connect-app.svg");
    background-color: $icon-button-color !important;
}

.wa_AgentManagedSourcesBodyHeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.wa_AgentManagedSourcesBodyHeaderRow_SearchFilter {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
}

.wa_AgentManagedSourcesBodyContent {
    display: flex;
}

.wa_AgentManagedSourcesBody_Error {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;

    svg {
        width: 24px;
        height: 24px;
        color: red;
    }
}

.wa_AgentManagedSourcesBody_Empty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 300px;
    gap: 16px;
    flex-direction: column;

    .wa_AgentManagedSourcesBody_Empty_icon {
        color: $icon-button-color;
    }

    .wa_AgentManagedSourcesBody_Empty_content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .wa_AgentManagedSourcesBody_Empty_title {
            font-size: $font-16px;
            font-weight: 600;
            margin: 0;
        }

        .wa_AgentManagedSourcesBody_Empty_desc {
            font-size: $font-14px;
            font-weight: 400;
            margin: 0;
        }
    }
}

.wa_AgentManagedSourcesBody_AppItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;

    .wa_AgentConnectorName_Block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wa_AgentConnectorName_Icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 8px;
            background-color: $background;
            margin-right: 4px;
        }

        .wa_AgentConnectorName_Text {
            color: var(--cpd-color-text-primary);
            font-size: $font-16px;
            font-weight: 500;
        }
    }

    .wa_AgentConnectorButton_Block {
        display: inline-block;

        .wa_AgentConnectorButton_Tooltip {
            position: relative;
            display: inline-block;
        }

        .wa_AgentConnectorButton_Outline {
            border: 1px solid #ccc;
            background-color: transparent;
            padding: 8px 16px;
            cursor: pointer;
        }

        .wa_AgentConnectorButton_Outline:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .wa_AgentConnectorButton_Content {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            bottom: 125%; /* Position above the button */
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .wa_AgentConnectorButton_Tooltip:hover .wa_AgentConnectorButton_Content {
            visibility: visible;
            opacity: 1;
        }
    }
}
