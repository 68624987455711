.wa_MangedSourcesUploadBar {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 16px 0px #00000014;
    width: 314px;
    height: 318px;
    position: absolute;
    bottom: 12px;
    border-radius: 8px;
}
.wa_MangedSourcesUploadBar_Minimized {
    height: 100px;
}
.wa_MangedSourcesUploadBar_Header {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    justify-content: space-between;
    background-color: $sidebar-color;
    align-items: center;

    .wa_MangedSourcesUploadBar_Header_Icons {
        display: flex;
        flex-direction: row;
        gap: 5px;
        opacity: 50%;

        color: $icon-button-color;
    }

    .wa_MangedSourcesUploadBar_Header_Title {
        font-size: $font-16px;
        font-weight: 700;
        color: $roomlist-text-color;
    }
}
.wa_MangedSourcesUploadBar_Content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;

    .wa_MangedSourcesUploadBar_Content_Upload_Count {
        font-size: $font-12px;
        font-weight: 600;
        line-height: 14px;
        padding: 18px 20px;
    }

    .wa_MangedSourcesUploadBar_Content_Upload_Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        padding: 8px 20px;

        &:first-child {
            margin-block-start: 10px;
        }

        &:last-child {
            margin-block-end: 10px;
        }

        &:hover {
            background-color: $roomlist-background-color;

            .wa_MangedSourcesUploadBar_Content_Upload_Cancel {
                visibility: visible;

                svg {
                    color: $warning-color;
                }
            }
        }

        .wa_MangedSourcesUploadBar_Content_Upload_Cancel {
            visibility: hidden;
        }

        .wa_MangedSourcesUploadBar_Content_Upload_Item_Icon {
            width: 40px;
            height: 40px;
            background-color: $roomlist-background-color;
            color: $icon-button-color;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 40px;
                height: 18px;
            }
        }

        .wa_MangedSourcesUploadBar_Content_Upload_Item_Progress {
            display: flex;
            flex-direction: column;
            gap: 4px;
            justify-content: flex-start;

            .wa_MangedSourcesUploadBar_Content_Upload_Item_Name {
                font-size: $font-12px;
                font-weight: 600;
                color: $roomlist-text-color;
                line-height: 14px;
            }

            .mx_ProgressBar {
                width: 190px;
                height: 4px;
                color: #4dae5d;
                border-radius: 30px;
                border: none;

                &::-moz-progress-bar {
                    background: #4dae5d;
                }
                &::-webkit-progress-value {
                    background: #4dae5d;
                }
            }

            .wa_MangedSourcesUploadBar_Content_Upload_Item_Progress_Percentage {
                font-size: $font-12px;
                font-weight: 500;
                color: $roomlist-text-color;
                opacity: 50%;
                line-height: 14px;
            }
        }
    }
}
