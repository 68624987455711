.table-wrapper {
    width: 100%;
    overflow: auto;
}

.table-wrapper--max-height {
    max-height: 50vh;
    overflow: scroll;
}

.table {
    width: 100%;
    font-size: $font-14px;
    border-collapse: collapse;
}

.table-header {
    & tr {
        background-color: $roomlist-background-color;
        border-bottom: 1px solid $roomlist-separator-color;
    }
}

.table-footer {
    background-color: $primary-color;
    color: $background;
    font-weight: var(--cpd-font-weight-semibold);
}

.table-row {
    border-bottom: 1px solid $roomlist-separator-color;
    transition: colors var(--transition-short);
}

.table-head {
    text-align: left;
    vertical-align: middle;
    font-weight: var(--cpd-font-weight-semibold);
    color: $secondary-content;

    &:has([role="checkbox"]) {
        padding-right: 0;
    }
}

.table-cell {
    border: 0;
    padding: var(--cpd-space-4x);
    vertical-align: middle;

    &:has([role="checkbox"]) {
        padding-right: 0;
    }
}

.table-caption {
    margin-top: var(--cpd-space-4x);
    font-size: $font-14px;
    color: $secondary-content;
}
