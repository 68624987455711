.wa_AiMessageActionsButtons {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px;
    width: 100%;
    margin-top: 8px;

    .wa_AiMessageActionsButtons_wrapper {
        border-radius: 8px;
        gap: 10px;
        border: 1px solid $roomlist-separator-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
    }

    .wa_AiMessageActionsButtons_button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        width: fit-content;
        background-color: $timeline-background-color;
        border-radius: 8px;

        &:hover {
            .wa_AiMessageActionsButtons_icon {
                color: $icon-hover-color;
            }
        }

        .wa_AiMessageActionsButtons_icon {
            color: $icon-default-color;
        }

        .wa_AiMessageActionsButtons_text {
            font-size: $font-12px;
            font-weight: var(--cpd-font-weight-medium);
            line-height: 16px;
        }
    }

    .wa_AiMessageActionsButtons_separator {
        width: 1px;
        height: 16px;
        background-color: $roomlist-separator-color;
    }
}
