.wa_Configuration_container {
    width: 100%;
    max-width: 600px;

    form {
        gap: 0 !important;
    }

    .wa_Configuration_label {
        font-family: inherit;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        margin-bottom: 8px;
        display: block;
        color: $timeline-text-color;
    }

    .wa_Configuration_label_description {
        margin: 0 0 16px 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: $settings-label-description-color;
    }

    input[type="text"],
    .wa_Configuration_input_textarea {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $settings-textarea-border-color;
        border-radius: 8px;
        line-height: 140%;
    }

    .wa_Configuration_actions_section {
        margin-top: 24px;
    }

    .wa_Configuration_textarea {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $settings-textarea-border-color;
        border-radius: 8px;
        line-height: 21px;
        resize: none;
        overflow: hidden;

        font-family: inherit;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        color: $timeline-text-color;
    }
}
