/*
Copyright 2019-2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_Login_submit {
    @mixin mx_DialogButton;
    font-size: 15px;
    font-weight: var(--cpd-font-weight-semibold);
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    box-sizing: border-box;
    text-align: center;
}

.mx_Login_submit:disabled {
    opacity: 0.3;
    cursor: default;
}

.mx_Login_loader {
    display: inline;
    position: relative;
    top: 2px;
    left: 8px;
}

.mx_Login_loader .mx_Spinner {
    display: inline;
}

.mx_Login_loader .mx_Spinner img {
    width: 16px;
    height: 16px;
}

.mx_Login_error {
    color: $alert;
    font-weight: bold;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.mx_Login_error.mx_Login_serverError {
    text-align: left;
    font-weight: normal;
}

.mx_Login_error.mx_Login_serverError.mx_Login_serverErrorNonFatal {
    color: #ff8d13; /* Only used here */
}

.mx_Login_type_container {
    display: flex;
    align-items: center;
    color: $authpage-primary-color;

    .mx_Field {
        margin: 0;
    }
}

.mx_Login_type_label {
    flex: 1;
}

.mx_Login_underlinedServerName {
    width: max-content;
    border-bottom: 1px dashed $accent;
}

div.mx_AccessibleButton_kind_link.mx_Login_forgot {
    display: block;
    margin-top: 24px;

    &.mx_AccessibleButton_disabled {
        cursor: not-allowed;
    }
}

.mx_Login_spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 14px;
}

.mx_Login_fullWidthButton {
    width: 100%;
    margin-bottom: 16px;
}

.wa_AuthPageWrapper {
    border-radius: 24px;
    box-shadow: 0px 16px 32px 0px #00000014;

    .wa_LoginWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 32px;
        border-radius: 24px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .wa_LoginInfoBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding-top: 32px;
            padding-bottom: 20px;

            .wa_LoginTitle {
                line-height: 23px;
                margin: 0px;
            }
    
            .wa_LoginDesc {
                font-size: $font-16px;
                line-height: 20px;
            }
        }

        .wa_LoginForm {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 16px;
            padding-bottom: 32px;
            gap: 32px;
            width: 100%;

            .wa_LoginHSUrl {
                font-weight: var(--cpd-font-weight-semibold);
                font-size: $font-16px;
                color: #1D0941;
                opacity: 50%;
            }

            .wa_SSOButtton {
                width: 100%;
                .mx_SSOButton {
                    width: 100%;
                    border-radius: 38px;
                }
                .wa_SSOButton_label {
                    font-size: $font-16px;
                    font-weight: var(--cpd-font-weight-semibold);
                    width: 100%;
                    display: flex;

                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                }
            }
        }


        .mx_AuthHeaderLogo {
            width: 60px;
            height: 60px;
        }
    }
    .wa_FooterWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px 32px;
        background: linear-gradient(180deg, #faf9ff 0%, #ffffff 100%);
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        .wa_AuthBody_changeFlow {
                display: flex;
                flex-direction: column;
                gap: 12px;
            

            .wa_AuthBody_signUp_desc {
                opacity: 50%;
                font-size: $font-14px;
            }
            .wa_AuthBody_signUp_link {
                font-size: $font-14px;
                // TODO: move this color to the theme
                color: #1d0941;
            }
        }
    }
}
