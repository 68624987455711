.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: var(--cpd-space-5x);
}

.pagination_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.pagination_size {
    font-size: $font-14px;
    font-weight: 400;
    color: $roomlist-text-color;
}

.pagination__list {
    display: flex;
    gap: var(--cpd-space-2x);
    justify-content: center;
    padding-inline: 0px;
}

.pagination__button {
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: $font-13px;
    font-weight: 600;
    text-decoration: none !important;
}

.pagination__button-icon {
    color: $roomlist-text-color;

    svg {
        width: 20px;
        height: 20px;
    }
}

.pagination-cell {
    position: relative;
    height: 20px;
    min-width: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    background-color: $background;
    text-align: center;
    font-size: $font-12px;
    font-weight: var(--cpd-font-weight-semibold);
    color: $roomlist-text-color;
}

.pagination-cell--selected {
    background-color: $primary-color;
    color: $timeline-background-color;
    border: 1px solid $roomlist-separator-color;
}

.pagination-dots {
    position: absolute;
}

.pagination-dots--prev {
    left: 3px;
    top: 5px;
}

.pagination-dots--next {
    left: 3px;
    top: 5px;
}

.pagination-dots__icon {
    color: $roomlist-text-color;
}
