/*
Copyright 2024 Wand.ai
 */

.wa_CatchupModal_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .wa_CatchupModal_header {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        border-bottom: 1px solid $roomlist-separator-color;

        .wa_CatchupModal_header_options {
            display: flex;
            gap: 16px;
            flex-direction: row;
            align-items: center;

            .wa_CatchupModal_header_options_toggle_wrapper {
                display: flex;
                gap: 6px;
                flex-direction: row;
                align-items: center;
            }

            .wa_CatchupModal_header_options_toggle_title {
                font-size: $font-12px;
                font-weight: var(--cpd-font-weight-semibold);
            }

            .wa_CatchupModal_header_expand_button {
                svg {
                    color: $icon-button-color;
                }
            }
        }

        .wa_CatchupModal_header_title {
            font-size: $font-20px;
            font-weight: var(--cpd-font-weight-semibold);
            margin: 0;
        }
    }
    .wa_CatchupModal_content {
        width: calc(100% - 16px);
        height: 100%;
        overflow: scroll;
        padding-inline: 8px;
    }
}
