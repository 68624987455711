.wa_AgentManagedSourcesFilters {
    display: flex;
    gap: 8px;

    &_button {
        border: 1px solid $roomlist-background-color !important;
        font-size: $font-12px !important;
        text-transform: capitalize;

        &--active {
            color: var(--cpd-color-text-on-solid-primary) !important;
            background-color: $accent-color;
            border-color: $accent-color !important;
        }
    }
}
