html[lang^="ar"],
html[lang^="he"],
html[lang^="fa"],
html[lang^="ur"],
html[lang^="ps"],
html[lang^="sd"],
html[lang^="ku"],
html[lang^="ug"] {
    .mx_ScrollPanel .mx_RoomView_MessageList {
        direction: rtl;
        text-align: right;
    }

    .mx_EventTile .mx_EventTile_body {
        text-align: right;
        table {
            text-align: center;
        }
    }

    .mx_EventTile[data-layout="group"] .mx_EventTile_avatar {
        right: 8px;
        left: auto;
    }

    .mx_RoomView_timeline_rr_enabled .mx_EventTile[data-layout=group] .mx_EventTile_line,
    .mx_RoomView_timeline_rr_enabled .mx_EventTile[data-layout=group] .mx_ThreadSummary,
    .mx_RoomView_timeline_rr_enabled .mx_EventTile[data-layout=group] .mx_ThreadSummary_icon {
        margin-left: 80px;
        margin-right: 0;
    }

    .mx_EventTile[data-layout=group] .mx_EventTile_line,
    .mx_EventTile[data-layout=group] .mx_EventTile_reply {
        padding-right: var(--EventTile_group_line-spacing-inline-start);
        padding-left: 0;
    }

    .mx_MessageActionBar {
        left: 8px;
        right: auto;
    }

    .mx_EventTile[data-layout="group"] > .mx_DisambiguatedProfile {
        margin-left: 0;
        margin-right: 64px;
    }

    .mx_EventTile_content {
        margin-right: 0;
        margin-left: var(--EventTile_content-margin-inline-end);

        .markdown-body {
            direction: rtl;

            blockquote {
                border-left: none;
                border-right: 2px solid var(--cpd-color-border-interactive-primary);
            }
        }
    }

    .mx_EventTile[data-layout="irc"] .mx_EventTile_msgOption,
    .mx_EventTile[data-layout="group"] .mx_EventTile_msgOption {
        float: left;
        text-align: left;
    }

    .mx_JumpToBottomButton {
        right: auto;
        left: 24px;
    }

    .mx_EventTile[data-layout="group"] .mx_MessageTimestamp {
        left: auto;
        right: 0;
    }

    .mx_EventTile_lineNumbers {
        float: right;
        margin: 0 -1.5rem 0 0.5em;

        span {
            padding-left: 0;
            padding-right: 1rem;
        }
    }

    .mx_EventTile_body .mx_EventTile_pre_container {
        .mx_EventTile_button {
            right: auto;
            left: 8px;
        }

        .mx_EventTile_copyButton {
            right: auto;
            left: 9px;
        }
    }

    .wa_EventTile_savedMessageHeader {
        left: auto;
        right: 60px;
    }

    .mx_TopUnreadMessagesBar {
        right: auto;
        left: 24px;
    }

    .mx_MessageComposer_row {
        flex-direction: row-reverse;
    }

    .mx_MessageComposer_actions {
        flex-direction: row-reverse;
    }

    .mx_MessageComposer_wrapper {
        padding-right: 42px;
        padding-left: 16px;

        &:has(.wa_MessageComposer_openEAButton) {
            padding-left: 0;
        }
    }

    .wa_MessageComposer_openEAButton {
        margin-inline-start: 0;
        margin-inline-end: 16px;
    }

    .mx_SendMessageComposer {
        margin-left: 6px;
        margin-right: 0;
    }

    .mx_SendMessageComposer .mx_BasicMessageComposer .mx_BasicMessageComposer_input {
        direction: rtl;
    }

    .mx_ContextualMenu_right {
        right: auto;
        left: 0;
    }

    .mx_ContextualMenu {
        direction: rtl;
    }

    .mx_EventTile_details {
        margin-left: 0;
        margin-right: var(--leftOffset);
    }

    .mx_IconizedContextMenu .mx_IconizedContextMenu_optionList .mx_IconizedContextMenu_item .mx_IconizedContextMenu_icon + .mx_IconizedContextMenu_label {
        padding-left: 0;
        padding-right: 14px;
    }

    .mx_ThreadSummary {
        .mx_ThreadSummary_content {
            margin-left: 0;
            margin-right: 4px;
        }

        .mx_ThreadSummary_chevron {
            background: linear-gradient(450deg, var(--cpd-color-bg-subtle-primary) 50%, #e1e6ec00 100%);
            right: auto;
            left: 0;
            transform: translateX(-60px);

            &:before {
                mask-image: url("@vector-im/compound-design-tokens/icons/chevron-left.svg");

                right: auto;
                left: var(--cpd-space-1x);
            }
        }

        &:hover .mx_ThreadSummary_chevron {
            transform: translateX(0);
        }
    }

    .mx_MessageComposer_sendMessage {
        transform: rotate(180deg);
    }

    .wa_AIPanel {
        .mx_EventTile_content {
            margin-right: 0 !important;
        }

        .mx_EventTile[data-layout="bubble"] {

            .mx_EventTile_line {
                position: static;
            }

            &:not(.mx_EventTile_noBubble) {
                .mx_EventTile_line:not(.mx_EventTile_mediaLine) {
                    .mx_EventTile_content {
                        margin-left: 0;
                        margin-right: var(--EventTile_content-margin-inline-end);
                    }
                }
            }
        }

        .mx_MessageActionBar {
            inset-inline-end: -13px !important;
            right: auto !important;
        }
    }

    .wa_SavedEventTile_body_origin_wrapper {
        text-align: right;
    }

    .wa_SavedEventTile_body_wrapper {
        direction: rtl;

        .mx_EventTile_content {
            text-align: right;
        }
    }

    .mx_Autocomplete_ProviderSection {
        direction: rtl;
    }

    .mx_ThreadPanel {
        &.mx_ThreadView {
            .mx_Autocomplete {
                left: -140px;
            }
        }

        .mx_EventTile[data-layout="group"] {
            .mx_MessageActionBar {
                right: auto;
                left: 0;
            }
        }
    }

    .mx_Autocomplete_Completion_pill > *:not(:last-child) {
        margin-right: 0;
        margin-left: 0.3rem;
    }

    .mx_EventTile[data-shape="ThreadsList"] .mx_EventTile_line,
    .mx_EventTile[data-shape="Notification"] .mx_EventTile_line {
        padding-right: var(--leftOffset);
    }

    .mx_ThreadView .mx_EventTile[data-layout="irc"] .mx_EventTile_line,
    .mx_ThreadView .mx_EventTile[data-layout="group"] .mx_EventTile_line {
        padding-right: 48px;
    }

    .mx_EventTile_annotated {
        direction: rtl;
    }
}
